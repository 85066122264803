import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlModel, FormControlOptionModel, FormControlSelectDeviceAttributeModel, FormControlTextModel } from '@/model/form-control';
import { QueryModel } from '@/model/query-model';
import { DeviceTypeEnum, FacilityLinkType, FormControlSelectDeviceAttributeType } from '@/model/enum';
import CommonService from '@/service/common';
import { getDeviceIdAndAttributeKey } from './device-entity';
import { NUMBER_LETTER_UNDERLINE_REGEXP } from '@/model/regexp';
import { facilityLinkTypeList, facilityLinkTypeFilter } from '@/filter/facility-bind-type';

export class FacilityConfigAttributeEntityModel extends BaseEntityModel {
    constructor(isFacilityType: boolean, entityId: string) {
        super();
        this.isFacilityType = isFacilityType;
        this.entityId = entityId;
    }

    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true,
        readonly: true,
        max: 255
    } as FormControlModel<string>)
    name: string = undefined;

    @FormControl({
        label: '标识符',
        type: FormControlType.TEXT,
        required: true,
        max: 50,
        pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        readonly: true
    } as FormControlTextModel)
    identifier: string = undefined;

    @FormControl({
        label: '关联方式',
        type: FormControlType.SELECT,
        options: facilityLinkTypeList,
        required: true,
        cascad: true,
        readonly: true
    } as FormControlOptionModel)
    linkType: FacilityLinkType = undefined;

    get type() {
        return this.linkType;
    }

    @FormControl({
        label: '关联产品类型',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getDeviceTypeOptions,
        optionsPromiseParam: [DeviceTypeEnum.DEVICE],
        mode: 'multiple',
        invisibleFunction: model => model.linkType !== FacilityLinkType.DEVICE,
        required: true
    } as FormControlOptionModel)
    linkDeviceTypeList: Array<string> = undefined;

    @FormControl({
        label: '关联设备属性范围',
        type: FormControlType.SELECT_DEVICE_ATTRIBUTE,
        mode: 'multiple',
        selectType: FormControlSelectDeviceAttributeType.DEVICE_TYPE,
        invisibleFunction: model => model.linkType !== FacilityLinkType.DEVICE_ATTR,
        required: true
    } as FormControlSelectDeviceAttributeModel)
    linkDeviceAttributeList: Array<string> = undefined;

    isFacilityType: boolean = undefined;
    entityId: string;

    get dataValue() {
        let selectValue = null;
        switch (this.linkType) {
            case FacilityLinkType.DEVICE:
                selectValue = _.get(this.linkDeviceTypeList, '[0]');
                break;
            case FacilityLinkType.DEVICE_ATTR:
                selectValue = _.map(this.linkDeviceAttributeList, item => {
                    const { deviceId, attributeKey } = getDeviceIdAndAttributeKey(item);
                    return `${deviceId}|${attributeKey}`;
                });
                break;
        }
        return selectValue;
    }

    set dataValue(val: any) {
        let _dataValue = null;
        if (val) {
            _dataValue = Array.isArray(val) ? val : [val];
        }
        switch (this.linkType) {
            case FacilityLinkType.DEVICE:
                this.linkDeviceTypeList = _dataValue;
                break;
            case FacilityLinkType.DEVICE_ATTR:
                this.linkDeviceAttributeList = _dataValue;
                break;
        }
    }

    // dataValue: any = undefined;
    // displayName: string = undefined;
    // feedback:boolean=false;
    // validatestatus:string='success';
    relations: Array<any> = undefined;

    // get DisplayNameHtml() {
    //     if (this.displayName) {
    //         return this.displayName;
    //     }
    //     return '-';
    // }

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '属性类型',
                dataIndex: 'linkType',
                customRender: (text) => {
                    return facilityLinkTypeFilter(text);
                }
            },
            {
                title: '最后更新时间',
                dataIndex: 'modifiedTime'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    static getFacilityTabelColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                width: 150
            },
            {
                title: '标识符',
                dataIndex: 'identifier',
                width: 150
            },
            {
                title: '属性类型',
                dataIndex: 'linkType',
                customRender: (text) => {
                    return facilityLinkTypeFilter(text);
                },
                width: 150
            },
            {
                title: '数据定义',
                dataIndex: 'dataValue',
                scopedSlots: { customRender: 'dataValue' }
            },
            {
                title: '最后更新时间',
                dataIndex: 'modifiedTime',
                width: 150
            }
            // {
            //     title: '操作',
            //     dataIndex: 'action',
            //     scopedSlots: { customRender: 'action' },
            //     width: 150
            // }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        switch (this.linkType) {
            case FacilityLinkType.DEVICE:
                this.linkDeviceTypeList = _.map(_.get(json, 'relations'), item => item.entityId);
                // this.displayName = _.map(_.get(json, 'relations'), item => item.entityName).join('<br>');
                break;
            case FacilityLinkType.DEVICE_ATTR:
                this.linkDeviceAttributeList = _.map(_.get(json, 'relations'), item => `${item.entityId}|${item.attrKey}`);
                // this.displayName = _.map(_.get(json, 'relations'), item => {
                //     return `${item.entityName} | ${item.attrName}(${item.attrKey})`;
                // }).join('<br>');
                break;
        }
        return this;
    }

    toService() {
        const data: any = super.toService();
        switch (this.linkType) {
            case FacilityLinkType.DEVICE:
                data.relations = _.map(this.linkDeviceTypeList, item => {
                    return { entityId: item };
                });
                break;
            case FacilityLinkType.DEVICE_ATTR:
                data.relations = _.map(this.linkDeviceAttributeList, item => {
                    const { deviceId, attributeKey } = getDeviceIdAndAttributeKey(item);
                    return {
                        entityId: deviceId,
                        attrKey: attributeKey
                    };
                });
                break;
        }
        data.statsId = this.entityId;
        delete data.isFacilityType;
        delete data.entityId;
        delete data.linkDeviceTypeList;
        delete data.linkDeviceAttributeList;
        return data;
    }
}

export class FacilityConfigAttributeQueryModel extends QueryModel {
    constructor(entityId: string, isFacilityType: boolean) {
        super();
        this.isFacilityType = isFacilityType;
        this.entityId = entityId;
    }
    isFacilityType: boolean = undefined;
    entityId: string = undefined;

    // toService() {
    //     const data = super.toService();
    //     return data;
    // }
}
